<template>
    <div class="contenedor">

        <rutaPagina :volver="false" :ruta="ruta" />

        <div class="row glass"  >

            <tituloPagina texto="Acuerdo de confidencialidad" />

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                <h3>Copyright</h3>
                <p>© 2018 RESTREPO GOMEZ S.A.S Todos los derechos reservados.</p>
                <p>La entidad RESTREPO GOMEZ S.A.S (que en este documento llamaremos RG S.A.S) publica y mantiene este sitio. A menos que se indique lo contrario, todo el contenido de este sitio es propiedad intelectual de RESTREPO GOMEZ S.A.S (RG S.A.S).</p><br>
                <h3>Términos y Condiciones de Uso</h3>
                <p>El uso de este sitio está regido por los presentes Términos y Condiciones de Uso. Al usar este sitio, usted se compromete a cumplir todos los Términos y Condiciones de Uso, así como los Términos y Condiciones de Uso Adicionales (en conjunto, los Términos y Condiciones de Uso) detallados en este sitio de Internet. No debe utilizar este sitio si no está dispuesto a cumplir estos términos y condiciones en su totalidad o en parte.</p><br>
                <p>¿Qué implica el uso adecuado de este sitio?</p>
                <ul>
                <li> Ver, descargar o imprimir publicaciones electrónicas y cualquier otro tipo de texto, así como ilustraciones, imágenes, videos y grabaciones de audio de RG S.A.S. Sin embargo, solo debe hacerlo para su propio uso y no puede usar el contenido con fines comerciales.</li>
                <li> Enviar enlaces o copias electrónicas de archivos que se pueden descargar, de videos o de grabaciones de audio.</li>
                </ul>
                <p>Sin embargo, debe sujetarse a las siguientes restricciones:</p>
                <ul>
                <li> Publicar ilustraciones, publicaciones electrónicas, marcas de productos, música, imágenes, videos o artículos de este sitio en cualquier otro sitio de Internet (ni tampoco en redes sociales ni en páginas para compartir videos o archivos).</li>
                <li> Distribuir ilustraciones, publicaciones electrónicas, marcas de productos, música, imágenes, texto o videos de este sitio como parte de cualquier programa o aplicación electrónica (esto incluye guardar esta información en un servidor para usarla en una aplicación informática).</li>
                <li> Reproducir, copiar, distribuir o usar ilustraciones, publicaciones electrónicas, marcas de productos, música, imágenes, texto o videos de este sitio con fines comerciales o a cambio de dinero (aunque no sea para obtener beneficios económicos).</li>
                <li> Crear programas o herramientas informáticos o desarrollar técnicas para recopilar, copiar, descargar o extraer datos, páginas HTML, imágenes o textos de este sitio a fin de distribuirlos. (No está prohibida la distribución de aplicaciones gratuitas y no comerciales diseñadas para descargar archivos electrónicos como archivos EPUB, PDF, MP3 y MP4 de las secciones públicas de este sitio).</li>
                <li> Usar indebidamente el sitio o sus servicios; por ejemplo, interferir con el sitio o sus servicios o acceder a ellos con un método distinto al que se provee expresamente.</li>
                <li> Usar este sitio de una manera que pudiera dañarlo o impedir el acceso a él. Tampoco debe usarse de manera ilícita, ilegal, fraudulenta o perjudicial, o que de algún modo esté asociada a alguna actividad o propósito de carácter ilícito, ilegal, fraudulento o perjudicial.</li>
                <li> Usar este sitio o cualquiera de sus ilustraciones, publicaciones electrónicas, marcas de productos, canciones, imágenes, textos o videos con algún fin publicitario.</li>
                </ul><br>
                <h3>Exención de garantías y limitación de responsabilidad</h3>
                <p>Este sitio de Internet, todo su contenido e información, además de otros servicios disponibles mediante este sitio se proporcionan mediante RG S.A.S tal cual. RG S.A.S  no hace representaciones ni garantías de ningún tipo, ni implícitas ni explícitas.</p>
                <p>RG S.A.S  no garantiza la ausencia de virus u otros componentes dañinos en el sitio web. RG S.A.S  no se responsabiliza de los daños y perjuicios causados al usuario como consecuencia del uso de cualquier servicio, información, contenido o materia, así como de otros servicios que este sitio pone a su disposición, incluyendo, pero no limitándose, a daños directos, indirectos, fortuitos, punitivos o emergentes (incluida la pérdida de ganancias).<br>
                </p><br>
                <h3>Incumplimiento de Términos y Condiciones de Uso</h3>
                <p>Sin perjuicio de los demás derechos que estos Términos y Condiciones de Uso conceden a RG S.A.S, si usted viola o infringe alguno de estos Términos y Condiciones de Uso, RG S.A.S  se reserva el derecho de tomar las medidas que estime oportunas. RG S.A.S  podrá retirarle temporalmente el acceso al sitio o denegárselo por completo, bloquear el acceso al sitio a las computadoras que tengan su dirección IP, pedirle a su proveedor de servicios de Internet que restrinja su acceso al sitio y/o emprender acciones legales contra usted.</p><br>
                <h3>Modificaciones</h3>
                <p>RG S.A.S podrá modificar en cualquier momento estos Términos y Condiciones de Uso. Cualquier revisión de este documento entrará en vigor el mismo día de su publicación en el sitio. Consúltelo regularmente para estar al tanto de cualquier modificación.<br>
                </p>
                <br>
                <br>	
            </div>

        </div>
    </div>
</template>

<script>
import rutaPagina from '@/components/RutaPagina'
import tituloPagina from '@/components/Titulopagina'

export default {
    name: 'Acuerdo confidencialidad',
    setup(props) {

        const ruta = [
            { nombre : 'Administración'},
            { nombre : 'Acuerdo de confidencialidad'},
        ]

        return{
            ruta,
        }

    },
    components : {
        rutaPagina,
        tituloPagina
    }
}
</script>

<style scoped>
.glass h3 {
    font-size: 1rem;
    FONT-WEIGHT: bold;
    text-align: left;
}
.glass p {
    font-size: 0.8rem;
    text-align: justify;
}
.glass li {
    font-size: 0.8rem;
    text-align: justify;
}
</style>